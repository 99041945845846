
import {defineComponent, PropType, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import NewUserForm from "@/components/forms/NewUserForm.vue";
import {AddUserInput, GetUserResult} from "@/shared/types";
import {useManagersStore} from "@/store/managers/ManagerStore";

export default defineComponent({
  name: "EditManagerModal",
  components: {NewUserForm, ModalWrapper},
  emits: {
    onClose: () => true
  },
  props: {
    manager: {
      type: Object as PropType<GetUserResult>,
      required: true
    }
  },
  setup(props, {emit}) {
    const isOpen = ref(true)
    const onManagerEdit = async () => emit('onClose')
    const managerStore = useManagersStore()

    const onUser = async (user: Omit<AddUserInput, 'password'>) => {
      await managerStore.editManager({...user, uid: props.manager.uid})
      emit('onClose')
    }
    return {isOpen, onManagerEdit, onUser}
  }
})
