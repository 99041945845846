import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white border-b border-gray-200" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_6 = { class: "px-6 py-4 whitespace-nowrap text-right text-sm font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTableEditDeleteButtons = _resolveComponent("BaseTableEditDeleteButtons")!
  const _component_EditManagerModal = _resolveComponent("EditManagerModal")!
  const _component_AreYouSureModal = _resolveComponent("AreYouSureModal")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.manager.name), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.manager.email), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.manager.phone), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.managerClinicsCount), 1),
    _createElementVNode("td", _hoisted_6, [
      _createVNode(_component_BaseTableEditDeleteButtons, {
        onOnDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteManagerModel = true)),
        onOnEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editManagerModel = true)),
        "show-edit": _ctx.amIAdmin,
        "show-delete": _ctx.amIAdmin
      }, null, 8, ["show-edit", "show-delete"]),
      (_ctx.editManagerModel)
        ? (_openBlock(), _createBlock(_component_EditManagerModal, {
            key: 0,
            manager: _ctx.manager,
            onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editManagerModel = false))
          }, null, 8, ["manager"]))
        : _createCommentVNode("", true),
      (_ctx.deleteManagerModel)
        ? (_openBlock(), _createBlock(_component_AreYouSureModal, {
            key: 1,
            onOnAction: _ctx.onDeleteManagerModalClose
          }, null, 8, ["onOnAction"]))
        : _createCommentVNode("", true)
    ])
  ]))
}