
import { useManagersStore } from '@/store/managers/ManagerStore'
import { computed, defineComponent } from 'vue'
import ManagersListItem from './ManagersListItem.vue'

export default defineComponent({
  components: { ManagersListItem },
  setup() {
    const managersStore = useManagersStore()
    const managersState = managersStore.getState()

    const managers = computed(() => managersState.managers)
    return { managers }
  }
})
