
import {computed, defineComponent, PropType, ref} from 'vue'
import {GetUserResult} from '@/shared/types'
import {useManagersStore} from '@/store/managers/ManagerStore'
import BaseTableEditDeleteButtons from "@/components/utils/tables/BaseTableEditDeleteButtons.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useUserStore} from "@/store/user/UserStore";
import AreYouSureModal from "@/components/utils/modal/AreYouSureModal.vue";
import EditManagerModal from "@/components/managers/EditManagerModal.vue";

export default defineComponent({
  components: {EditManagerModal, AreYouSureModal, BaseTableEditDeleteButtons},
  props: {
    manager: {
      type: Object as PropType<GetUserResult>,
      required: true
    }
  },
  setup(props) {
    const managersStore = useManagersStore()
    const clinicsStore = useClinicsStore()
    const userStore = useUserStore()

    const clinicsState = clinicsStore.getState()
    const userState = userStore.getState()
    const amIAdmin = computed(() => userState.admin)

    const managerClinicsCount = computed(() => clinicsState.clinics.filter(clinic => clinic.managerUID === props.manager.uid).length)

    const deleteManagerModel = ref(false)
    const deleteManager = async () => {
      if (props.manager.email) {
        await managersStore.deleteManager(props.manager.email)
        await clinicsStore.setClinics(true)
      }
    }

    const onDeleteManagerModalClose = async (action: boolean) => {
      if (action) await deleteManager()
      deleteManagerModel.value = false
    }

    const editManagerModel = ref(false)
    return {managerClinicsCount, deleteManagerModel, amIAdmin, onDeleteManagerModalClose, editManagerModel}
  }
})
