
import AddManager from '@/components/managers/addmanager/AddManager.vue'
import ManagersList from '@/components/managers/ManagersList.vue'
import { useManagersStore } from '@/store/managers/ManagerStore'
import { useUserStore } from '@/store/user/UserStore'

import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  components: { AddManager, ManagersList },
  setup() {
    const userStore = useUserStore()
    const userState = userStore.getState()
    const managersStore = useManagersStore()
    onMounted(async () => {
      await managersStore.setManagers(false)
    })
    return { userState }
  }
})
